// custom typefaces
const scrollToElement = require("scroll-to-element")
require("./src/utils/css/screen.css")

exports.onRouteUpdate = ({ location }) => {
  checkHash(location)
}

const checkHash = location => {
  let { hash } = location
  if (hash) {
    scrollToElement(hash, {
      offset: -0,
      duration: 0,
    })
  }
}
